@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --bodyBg: #ffefeb;
    --textColor: #6e6245;
    --gold: #6e6245;
    --contentBlockBg: #fff7f3;
    --shadowColor: rgba(0, 0, 0, 0.1);
    --accentColor: #7f2b7c78;
  }

  .dark {
    --bodyBg: #0e0e0e;
    --textColor: #fff;
    --gold: #6e6245;
    --contentBlockBg: #1a1a1a;
    --shadowColor: rgba(0, 0, 0, 0.7);
    --accentColor: #6a00ff66;
  }
}

@layer components {
  h1 {
    @apply font-bold text-xl md:text-3xl lg:text-4xl;
  }

  h2 {
    @apply font-bold text-lg md:text-2xl lg:text-3xl;
  }

  h3 {
    @apply font-bold text-base md:text-xl lg:text-2xl;
  }

  h4 {
    @apply font-bold text-base md:text-lg lg:text-xl;
  }

  h5 {
    @apply font-bold text-base md:text-base lg:text-lg;
  }

  h6 {
    @apply text-base font-bold;
  }
}


.slideItem {
  max-height: 600px;
}

@media (min-width: 0px) {
  .slideItem {
    height: calc(300px + (600 - 300) * ((100vw - 320px) / (768 - 320)));
  }
}

@media (min-width: 769px) {
  .slideItem {
    height: calc(300px + (450 - 300) * ((100vw - 768px) / (1000 - 768)));
  }
}

@media (min-width: 1001px) {
  .slideItem {
    height: calc(300px + (380 - 300) * ((100vw - 1001px) / (1280 - 1001)));
  }
}

@media (min-width: 768px) {
  .slick-slide {
    padding: 0 20px;
  }
}

@for $i from 1 through 30 {
  @keyframes particle-animation-#{$i} {
    100% {
      transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
      -webkit-transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
      -moz-transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
      -ms-transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
      -o-transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
    }
  }

  .particle:nth-child(#{$i}) {
    $size: random(5)+5+px;
    opacity: calc(random(30)/70);
    height: $size;
    width: $size;
    animation-delay: -$i * .2s;
    top: random(100) * 1px;
    left: random(100) * 1px;
    animation: particle-animation-#{$i} 120s infinite;
    -webkit-animation: particle-animation-#{$i} 120s infinite;

    & {
      transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
      -webkit-transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
      -moz-transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
      -ms-transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
      -o-transform: translate3d((random(90) * 1vw), (random(20) * 1vh), (random(100) * 1px));
    }
  }
}